import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import * as S from "../styles/contato"



// colocar h1
const ContatoPage = () => {
  const { profileImage } = useStaticQuery(
    graphql`
        query {
            profileImage: file(relativePath: { eq: "profile.png" }) {
                childImageSharp {
                    fluid(maxWidth: 192) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }`
  )

  return (
    <Layout>
    <SEO 
      title="Contato" 
      description="Fotógrafo em São Paulo. Fotógrafo profissional com formação na escola Instituto Internacional de Fotografia, atuo em São Paulo nos segmentos da fotografia empresarial, eventos e retrato"
    />
      <h1 className="sr-only">Contato | Mikio Fotos</h1>
      <S.AboutWrapper>
        <S.SectionProfile>
          <S.ProfileImageWrapper>
            <Img fluid={profileImage.childImageSharp.fluid} width="192" height="192" alt="Mikio Fotos" />
          </S.ProfileImageWrapper>
        </S.SectionProfile>
        <S.SectionAbout>
          <S.AboutDescription>Formação profissional na escola Instituto Internacional de Fotografia, atuo em São Paulo nos segmentos da fotografia empresarial, eventos e retrato.</S.AboutDescription>
          <S.AboutDescription>Trabalhar com fotografia é minha realização pessoal e profissional. Faço fotos como uma forma de me conectar com o presente, perceber o que está em volta, reparar nos detalhes, uma prática da atenção plena.</S.AboutDescription>
          <S.AboutDescription>Procuro transmitir simplicidade, leveza e inspiração através das minhas fotos e tento combiná-los com as necessidades e objetivos de cada cliente, buscando, desse modo, os melhores resultados.</S.AboutDescription>
          <S.AboutDescription>No segmento da fotografia empresarial, procuro entender melhor o público alvo e suas motivações para que as fotos produzidas tenham um significado e um impacto positivo para os clientes.</S.AboutDescription>
        </S.SectionAbout>
        <S.SectionContact>
          <S.ContactWrapperList>
            <S.ContactListTitle>Contato</S.ContactListTitle>
            <S.ContactListDescription>
              <S.ContactListLink href="tel:11994077776" target="_blank" title="Telefone" rel="noopener noreferrer">(11) 99407-7776</S.ContactListLink>
            </S.ContactListDescription>
            <S.ContactListDescription>
              <S.ContactListLink href="mailto:ericksahara@gmail.com" target="_blank" title="E-mail" rel="noopener noreferrer">ericksahara@gmail.com</S.ContactListLink>
            </S.ContactListDescription>
            <S.ContactListDescription>
              <S.ContactListLink href="https://wa.me/5511994077776" target="_blank" title="Whatsapp" rel="noopener noreferrer">Whatsapp</S.ContactListLink>
            </S.ContactListDescription>
          </S.ContactWrapperList>
          <S.ContactWrapperList>
            <S.ContactListTitle>Social</S.ContactListTitle>
            <S.ContactListDescription>
              <S.ContactListLink  href="https://www.instagram.com/mikiofotos/" title="Instagram" target="_blank" rel="noopener noreferrer">Instagram</S.ContactListLink>
            </S.ContactListDescription>
          </S.ContactWrapperList>
        </S.SectionContact>
      </S.AboutWrapper>
    </Layout>
  )

}

export default ContatoPage