import styled from "styled-components"
import media from "styled-media-query";

export const AboutWrapper = styled.div`
display: flex;
margin-bottom: 140px;

${media.lessThan("small")`
    display: block;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
`}

`
export const SectionProfile = styled.div`
width: 192px;

${media.lessThan("small")`
    width: auto;
    margin-top: 30px;
`}

`
export const ProfileImageWrapper = styled.div`
width: 192px;

${media.lessThan("small")`
    width: 100px;
    margin: 0 auto 15px auto;
`}


`
export const SectionAbout = styled.div`
padding: 0 100px;

${media.lessThan("small")`
    padding: 0;
`}


`
export const AboutDescription = styled.p`
line-height: 1.5;
margin-bottom: 20px;

${media.lessThan("small")`
    line-height: 1.6;
`}


`

export const SectionContact = styled.div`
`
export const ContactWrapperList = styled.dl`
margin-bottom: 20px;
line-height: 1.5;
`
export const ContactListTitle = styled.dt`
font-family: 'Montserrat';
font-size: 12px;
text-transform: uppercase;
margin-bottom: 4px;
`
export const ContactListDescription = styled.dd`
`

export const ContactListLink = styled.a`
text-decoration: underline;
color: #000;
`